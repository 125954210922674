import VueCookies from 'vue-cookies';
import { cookiePrefix } from '@/config/constants.js';

export default {
  namespaced: false,
  state: {
    LANG: VueCookies.get(`${cookiePrefix}_LANG`) || null
  },
  getters: {
    LANG: (state) => state.LANG
  },
  mutations: {
    LANG(state, data) {
      VueCookies.set(`${cookiePrefix}_LANG`, data, '5y');
      state.LANG = data;
    }
  },
  actions: {
    LANG: ({ commit }, LANG_data) => {
      commit('LANG', LANG_data);
    }
  }
};
