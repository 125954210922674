import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import { cookiePrefix } from '@/config/constants.js';
import modules from '@/store/modules/';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    breadcumbs: null,
    lang: VueCookies.get(`${cookiePrefix}_locale`)
  },
  mutations: {
    setBreadcumbs(state, data) {
      state.breadcumbs = data;
    }
  },
  actions: {},
  modules
});
