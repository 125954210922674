import VueCookies from 'vue-cookies';
import { cookiePrefix } from '@/config/constants.js';

export default {
  namespaced: false,
  state: {
    DARK: Number(VueCookies.get(`${cookiePrefix}_DARK`) || 0)
  },
  getters: {
    DARK: (state) => state.DARK
  },
  mutations: {
    DARK(state, data) {
      VueCookies.set(`${cookiePrefix}_DARK`, data, Infinity);
      state.DARK = data;
    }
  },
  actions: {
    DARK: ({ commit }, DARK_data) => {
      commit('DARK', DARK_data);
      commit('map/update', { action: 'darkMode', data: DARK_data });
    }
  }
};
